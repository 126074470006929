<template>
  <v-container>
    <FileBrowser :username="username" :axiosConfig="{ baseURL: baseURL }">
    </FileBrowser>
  </v-container>
</template>

<script>
import FileBrowser from "vuetify-file-browser";
import axios from "axios";
export default {
  components: {
    FileBrowser,
  },
  data() {
    return {
      username: "",
      baseURL: process.env.VUE_APP_API_URL,
    };
  },
  methods: {
    getUserId() {
      axios
        .get(process.env.VUE_APP_API_URL + "/get-user-id")
        .then((response) => {
          this.username = response.data.name;
        });
    },
  },
  mounted() {
    this.getUserId();
  },
};
</script>